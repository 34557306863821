/* loading */
.meal-plan-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 80vh;
}

.meal-plan-loading img {
  height: auto;
}

.meal-plan-loading h4 {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

/* loading end */

/* tabs */
.meal-plan-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  padding: 1rem 0;
}

.meal-plan-tabs hr {
  border: none;
  border-left: 1px solid hsla(200, 10%, 50%, 100);
  height: 2rem;
  width: 1px;
  margin: 0 1rem;
}

.tab {
  font-size: 1.5rem;
  cursor: pointer;
  user-select: none;
  font-weight: 400;
  color: #666;
}

.tab.active {
  color: var(--maincolor);
  font-weight: 600;
}

/* tabs end */
.meal-wrapper {
  position: relative;
}

@media only screen {

  .meal-plan {
    display: flex;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .meal-plan-side {
    width: 60px;
    display: grid;
    grid-template-rows: 80px repeat(3, 1fr);
    border-right: 1px solid #ccc;
  }

  .meal-plan-main {
    flex: 1;
    display: grid;
    grid-template-rows: 80px repeat(3, 1fr);
    overflow-x: auto;
  }

  .meal-plan-main-row {
    display: flex;
  }

  .meal-plan-item {
    writing-mode: tb-rl;
    text-orientation: mixed;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
  }

  .meal-row-heading,
  .meal-row-item {
    width: 20%;
    flex: 0 0 auto;
  }

  .meal-row-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
  }

  .meal-row-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* meal plan item */
  .meal-plan-card {
    width: 14rem;
    overflow: hidden;
    position: relative;
  }

  .meal-plan-card-image {
    position: relative;
    height: 10rem;
    border-radius: 30px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .10), 0 4px 6px -2px rgba(0, 0, 0, .05);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .meal-plan-card-time {
    position: absolute;
    // width: 33.33%;
    bottom: 0;
    left: 0;
    padding: .25em .75em;
    text-align: center;
    background-color: #edf2f7;
    background-color: rgba(255, 255, 255, .85);
    border-top-right-radius: 0.5rem;
  }

  .meal-plan-card-time span {
    font-size: 1.15rem;
  }

  .meal-plan-card-title {
    font-weight: 500;
    font-size: 1.3rem;
    margin-top: 0.35rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .meal-plan-replace,
  .meal-plan-remove,
  .meal-plan-move {
    position: absolute;
    right: 8px;
    font-size: 1.5rem;
    z-index: 10;
    cursor: pointer;
    width: 1em;
    height: 1em;
    border-radius: 50%;
  }

  .meal-plan-replace {
    top: .5em;
    color: #000;
    background-color: #fff;
  }

  .meal-plan-remove {
    top: 1.75em;
    color: #d63230;
    background-color: #fff;
  }

  .meal-plan-move {
    top: 3em;
    color: #fff;
    cursor: move;
  }

  /* meal plan item end */

  /* meal plan drawer */
  .drawer {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #fff;
    height: 100%;
    width: 460px;
    margin: 0 auto;
    z-index: 100;
    box-shadow: 0 10px 45px 3px rgba(0, 0, 0, .10), 0 4px 8px -2px rgba(0, 0, 0, .05);
  }

  .drawer-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0;
    border: none;
  }

  .drawer-header {
    margin-top: 1rem;
    max-height: 100px;
  }

  .drawer-header h3 {
    font-size: 1.35rem;
    font-weight: 500;
    text-align: center;
    text-transform: capitalize;
  }

  .drawer-nav {
    display: flex;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
  }

  .drawer-nav-item {
    margin-right: 1rem;
    font-weight: 500;
    cursor: pointer;
    font-size: 1rem;
  }

  .drawer-nav-item:hover {
    color: #d63230;
  }

  .drawer-body {
    flex: 1;
    padding: 15px 15px 80px;
  }

  .drawer-label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .drawer-label span {
    text-transform: capitalize;
    font-size: 1.1rem;
  }

  .drawer-close {
    position: absolute;
    top: 5px;
    left: 10px;
    font-size: 1.5rem;
    cursor: pointer;
  }

  /* meal plan drawer end */

  /* meal plan drawer item */
  .drawer-item {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0 0 0.5em 0.2em rgba(0, 0, 0, .05);
    margin: 0.5rem 0;
  }

  .drawer-item-img {
    width: 100%;
    height: 10rem;
    object-fit: cover;
    object-position: center;
    border-bottom: 1px dashed #fff;
    transition: all 0.2s ease;
  }

  .drawer-item-img:hover {
    transform: scale(1.05);
  }

  .drawer-item-body {
    padding: 0.6rem 0.5rem;
  }

  .drawer-item-body p {
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.2;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .drawer-item-footer {
    display: flex;
    align-items: center;
  }

  .drawer-item-clock {
    font-size: 1.2rem;
    margin-right: 0.4rem;
  }

  .drawer-item-time {
    font-size: 1rem;
    font-weight: 500;
  }

  .drawer-item-add {
    text-align: right;
    flex: 1;
    padding: 0;
  }

  .drawer-item-add svg {
    font-size: 1.4rem;
    cursor: pointer;
  }
}

/* meal plan drawer item end */

@media only screen and (max-width: 576px) {
  .drawer {
    width: 260px;
  }
}

.print-meal-plan-main::-webkit-scrollbar {
  height: 10px;
}

.print-meal-plan-main::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 1em;

  &:hover {
    background-color: var(--maincolor);
  }
}

.cursore-move {
  cursor: move;
}

.drag-selected {
  border: 5px dashed #ccc;
  opacity: .5;
  cursor: move !important;
}

.drag-entered {
  border: 5px dashed var(--maincolor);
}

:-moz-drag-over,
:-webkit-drag-over,
:-ms-drag-over,
:-o-drag-over,
:drag-over {
  border: 5px dashed var(--maincolor);
}