@media only all {
  .print-meal-plan {
    display: block;
    width: auto;
    border: 1pt solid #ccc;
    border-left: none;
    border-radius: 4pt;
    margin-top: 16pt;
    // margin-left: 24pt;
    // margin-right: 24pt;
    // margin-bottom: 0;
  }

  .print-meal-plan-side {
    width: 100%;
    height: 36pt;
    display: grid;
    grid-template-columns: 36pt repeat(3, 1fr);
    border-bottom: 1pt solid #ccc;
  }

  .print-meal-plan-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16pt;
    font-weight: 500;
    border-left: 1pt solid #ccc;
  }

  .print-meal-plan-main {
    display: flex;
  }

  .print-meal-plan-main-row {
    display: block;
    width: 100%;
    padding-top: 16pt;
    border-left: 1pt solid #ccc;
  }

  .print-meal-plan-days {
    writing-mode: tb-rl;
    text-orientation: mixed;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 36pt;
    font-weight: 500;
  }

  .print-meal-row-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16pt;
    width: 36pt;
  }

  .print-meal-row-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* meal plan item */
  .print-meal-plan-card {
    width: 130pt;
    overflow: hidden;
    position: relative;
  }

  .print-meal-plan-card-image {
    position: relative;
    height: 85pt;
    border-radius: 10pt;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .print-meal-plan-card-time {
    position: absolute;
    // width: 33.33%;
    bottom: 0;
    left: 0;
    padding: 2pt 4pt;
    text-align: center;
    background-color: #edf2f7;
    background-color: rgba(255, 255, 255, 1);
    border-top-right-radius: 0.5rem;
  }

  .print-meal-plan-card-time svg {
    margin-right: 5pt;
  }

  .print-meal-plan-card-time span {
    font-size: 10pt;
  }

  .print-meal-plan-card-title {
    font-weight: 500;
    font-size: 1.2rem;
    margin-top: 0.35rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}