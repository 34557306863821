.admin {
  height: 100vh;
  width: 100%;
}

.admin-side-bar {
  height: 100vh;
  background-color: white;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
  width: 250px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: fixed;
}

.admin-side-bar~.admin-content {
  margin-left: 250px;
}

.admin-side-bar h4 {
  color: var(--maincolor) !important;
  font-weight: bold !important;
  cursor: pointer;
}

.divider {
  width: 100%;
  height: 3px;
  background: #ccc;
}

.admin-side-bar h4:hover {
  opacity: 0.8;
}

.admin-side-bar-nav {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  align-items: flex-start;
}

.admin-side-bar-nav p {
  width: 100%;
  text-align: left;
  padding: 10px;
  cursor: pointer;
  transition: 0.5s all;
  border-radius: 8px;
}

.admin-side-bar-nav p:hover {
  background-color: #ddd;
}

.admin-side-bar-nav svg {
  color: var(--maincolor);
  font-size: 18px;
  margin-right: 15px;
}

.admin-content {
  width: calc(100% - 250px);
  height: 100%;
}

.admin-nav {
  height: 65px;
  width: 100%;
  background-color: var(--maincolor);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
}

.admin-nav h5 {
  color: white;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.admin-nav svg {
  color: white;
  font-size: 25px;
  cursor: pointer;
  transition: 0.5s all;
}

.admin-nav svg:hover {
  opacity: 0.8;
}

.admin-wrapper {
  padding: 20px;
  overflow-y: scroll;
  height: calc(100% - 65px);
}

.admin-status-item {
  background-color: #eee;
  border-radius: 8px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
  margin: 10px 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.admin-status-item-img-container {
  flex: 1;
}

.admin-status-item img {
  height: 50px;
  width: 60px;
}

.admin-status-item p {
  margin: 0;
  font-weight: bold;
  padding: 0 20px;
  flex-grow: 1;
}

.modal-title,
.modal-header .close {
  color: inherit !important;
}

.item-action {
  display: flex;

  .icon-wrapper {
    background-color: white;
    padding: .25em;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    z-index: 1000;
    cursor: pointer;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);

    &:hover {
      opacity: 0.8;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
    }

    &.trash,
    .trash {
      color: rgba(255, 0, 0, 0.7);
    }

    &.check,
    .check {
      color: rgba(0, 120, 0, 0.7);
    }

    &.edit,
    .edit {
      color: rgba(0, 0, 0, 0.7);
    }
  }
}

.anticon.trash {
  color: rgba(255, 0, 0, 0.7) !important;
}

.anticon.check {
  color: rgba(0, 120, 0, 0.7) !important;
}

.anticon.edit {
  color: rgba(0, 0, 0, 0.7) !important;
}

.back-nav {
  color: var(--maincolor);
  margin-top: -140px;
  margin-left: 20px;
  cursor: pointer;
  transition: 0.5s all;
}

.back-nav:hover {
  opacity: 0.8;
}

.ToggleSwitch {
  background: linear-gradient(#ccc, #eee);
  border: 1px solid #bbb;
  height: 1.5em;
  width: 3em;
  border-radius: 1em;
  cursor: pointer;
}

.knob {
  cursor: pointer;
  position: relative;
  width: 1.4em;
  height: 1.4em;
  background: #aaa;
  border: 1px solid #ddd;
  border-radius: 50%;
  left: 0em;
  top: -0.5px;
  transition: left 0.3s ease-out;
}

.switch-active {
  left: 1.5em;
  background: var(--maincolor) !important;
  background-color: var(--maincolor) !important;
}