.footer {
  padding: 70px 0 10px !important;
}

.footer-logo {
  height: 10em;
  // width: 80%;
  margin-bottom: 1em;
}

.mouse-icon {
  background-color: var(--maincolor);
  border: 1px solid var(--maincolor);
}

.footer-icon {
  color: var(--maincolor);
  text-transform: uppercase;
  font-weight: bolder !important;
  font-size: 1.5rem !important;
}

.social-icon {
  cursor: pointer;
}