.form-floating,
.form-group {
  position: relative;

  .form-control {
    &.form-password {
      &.is-invalid {
        background-position: right calc(0.375em + 3.1875rem) center;
      }
    }
  }

  .password-control {
    cursor: pointer;
    position: absolute;
    // padding: 1rem 1rem;
    right: 1rem;
    bottom: 1rem;
    background-color: #efefef;
    overflow: hidden;
    border-radius: 3px;
  }

  .form-control.is-invalid~.password-control {
    bottom: 2.5rem;
  }
}
