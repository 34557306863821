.navbar-wrapper {
  position: relative;
}

.navbar {
  background-color: var(--maincolor);
  z-index: 10;
}

.navbar-brand {
  color: white;

  &:hover,
  &:focus {
    color: #ddd;
    // opacity: 0.7;
  }
}

.nav-link {
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  color: white;

  &:hover,
  &:focus {
    color: #ddd;
    opacity: 1;
  }
}

.nav-toggler {
  color: white;

  &:focus {
    border: none;
    outline: none;
  }
}

.nav-fade {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.nav-login-wrapper {
  cursor: pointer;
}

.nav-login {
  background-color: var(--maincolor);
  border: none;
  cursor: pointer;

  &:focus {
    background: var(--maincolor);
    background-color: var(--maincolor) !important;
    box-shadow: none !important;
    border: none;
    cursor: pointer;
  }

  &:hover {
    background-color: var(--maincolor);
    border: none;
  }
}

.dropdown-logout-wrapper {
  background-color: white;

  &:hover {
    background-color: white !important;
    background: white !important;
    opacity: 1 !important;
  }
}

.dropdown-logout {
  color: black;
  background-color: white;

  &:focus {
    background-color: white;
    color: black;
    outline: none;
  }
}
