.close span {
  cursor: pointer !important;
}

.close:focus {
  outline: none;
}

.forget_pass {
  font-size: 16px;
  cursor: pointer;
}

.forget_pass:hover {
  // text-decoration: underline;
  color: #d63230;
}

label.required {
  &:after {
    content: " *";
    color: #d63230;
  }
}
.required-notice {
  color: #d63230;
}
.ant-rate-star:not(:last-child) {
  margin-right: 5px;
}


.ant-btn-primary, .ant-btn-primary:hover {
  color: #fff;
  border-color: #d63230;
  background: #d63230;
}
