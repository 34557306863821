.recipes-empty {
  width: 100%;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-tabs .nav-item a.nav-link.active {
  color: rgb(214, 50, 48);
}

// @media only screen {

//   .print-recipe {
//     display: none;
//   }
// }
// @media only print {
//   .print-recipe {
//     display: block;
//     .recipe-thumb {
//       width: 100%;
//       height: 600pt;
//     }
//     h2 {
//       font-size: 28pt;
//       // position: relative;
//       // &:before {
//       //   content: "";
//       //   height: 1pt;
//       //   width: 100%;
//       //   display: block;
//       //   position: absolute;
//       //   background-color: #ccc;
//       //   top: -.5em
//       // }
//     }
//     p, li {
//       font-size: 18pt;
//       // font-weight: 500;
//     }
//     // .cooking-instructions {
//     //   font-size: 16pt;
//     // }
//     // .ingrediants {
//     //   page-break-before: always;
//     // }
//   }
// }