.admin-container {
  background: black;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.admin-form-box {
  width: 380px;
  background-color: var(--maincolor);
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 2%;
}

.admin-header-form {
  margin-bottom: 15px;
}

.admin-header-form h4 {
  color: white !important;
  font-weight: bold;
}

.admin-button {
  margin-top: 40px;
  margin-bottom: 10px;
  background: white !important;
  color: black !important;
  font-weight: 600 !important;
}

.admin-message {
  display: flex;
  justify-content: space-between;
}

.admin-message input {
  margin-right: 10px;
}

.admin-body-form .invalid-feedback {
  color: #fff !important;
}
