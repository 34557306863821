.submit-recipe-add-more {
  margin-top: 1em;
  cursor: pointer;
  font-size: 14px;
  color: var(--maincolor);
  opacity: 0.8;
  background-color: transparent;
  border: none !important;
  outline: none !important;
}

.submit-recipe-add-more:hover {
  opacity: 1;
  text-decoration: underline;
}

.submit-recipe-img-upload {
  input {
    display: none;
  }

  label {
    img {
      height: 150px;
      width: 220px;
      background-color: #eee;
      object-fit: contain;
      object-fit: center;
      cursor: pointer;
    }
  }
}

.ingredient-field-trash-icon {
  color: #888;
  cursor: pointer;
  font-size: 1.2rem;
  transition: all 500ms ease-out;
  background-color: white;
  border: none;
  outline: none;
}

.ingredient-field-trash-icon {
  &:hover {
    color: var(--maincolor);
  }

  &:focus {
    border: none;
    outline: none;
  }

  &:disabled {
    color: #ccc;
  }
}

/* / timer / */
.timer-input {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.timer-drop-down {
  position: absolute;
  top: 95px;
  left: 0;
  width: 40%;
  height: 200px;
  display: flex;
  justify-content: center;
  z-index: 10;
  border: 1px solid #ccc;
  background-color: #fff;
  user-select: none;
}

.timer-drop-down ul {
  list-style: none;
  cursor: pointer;
  width: 50%;
  height: 100%;
  padding: 0;
  text-align: center;
  overflow-y: auto;
}

.timer-hours,
.timer-minutes {
  border-right: 1px solid #ccc;
}

.timer-drop-down ul li:hover {
  background-color: #f7f6f2;
}

.timer-drop-down ul::-webkit-scrollbar {
  width: 5px;
}

.timer-drop-down ul::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 50px;
}

/* new changes */
.billing-form .form-control {
  color: black !important;
  font-size: 1rem;
}

.billing-form label {
  font-size: 1rem;
}

#cke_bottom_detail,
.cke_bottom {
  display: none;
}

.ingredients {
  max-height: 25em;
  overflow-y: auto;
}

.ingredients::-webkit-scrollbar {
  width: 5px;
  /* width of the entire scrollbar */
}

.ingredients::-webkit-scrollbar-thumb {
  background-color: #f7f6f2;
}
