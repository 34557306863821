.carousel-item {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 700px;
  max-height: 90vh;
  background-position: center;
  background-size: cover;
  float: none;
  background-repeat: no-repeat;
}

.carousel-item-sub {
  width: 100%;
  height: 700px;
  max-height: 90vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.carousel-heading {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 7rem;
  margin-bottom: .25em;
  color: white;
  font-family: "Amatic SC", cursive;
  cursor: context-menu;
}

@media (max-width: 678px) {
  .carousel-item {
    height: 500px;
    max-height: 90vh;
  }

  .carousel-item-sub {
    height: 500px;
    max-height: 90vh;
  }

  .carousel-heading {
    font-size: 4rem;
  }
}

.carousel-subtitle {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  letter-spacing: 4px;
  cursor: context-menu;
}

.submit-recipe-btn:focus {
  box-shadow: none !important;
  border-color: var(--maincolor) !important;
  outline-color: var(--maincolor) !important;
  background-color: transparent !important;
  color: var(--maincolor) !important;
}

.category {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.category-wrap-2 {
  background: rgba(214, 50, 48, 0.2);
  overflow: hidden;
}

.category-wrap-2 h2 {
  color: white !important;
  font-weight: bold;
}

.category-wrap-2 p {
  color: white !important;
  font-weight: 300;
}

.category-wrap-2 a {
  color: var(--maincolor) !important;
  background-color: white !important;
  border: 1px solid white !important;
}

.category-wrap-2 a:hover {
  color: white !important;
  border: 1px solid var(--maincolor) !important;
  background-color: var(--maincolor) !important;
}

.category-wrap-2 a:focus {
  box-shadow: none !important;
}

.categories-title {
  background-color: var(--maincolor) !important;
}

.categories-title p {
  cursor: pointer !important;
  margin: 3px 0;
}

.featured-bottom {
  color: #fff;
  width: 100%;
  background: var(--maincolor);
  width: 40px;
  height: 40px;
  margin: 0 auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
}

.img-prod img {
  height: 220px;
  width: 100%;
  object-position: center !important;
  object-fit: contain !important;
}

.bottom-area a {
  background: var(--maincolor) !important;
}

.price {
  color: var(--maincolor) !important;
}

.ad-home {
  height: 600px;
}

.categories-or-text {
  color: var(--maincolor);
  font-size: 1.5rem;
  font-weight: bold;
}

.loading-wrapper {
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
