@media only screen {
  .print-container {
    display: none;
  }

  .page-break {
    display: none;
  }
}

@media only print {
  .print-container {
    display: block;
  }

  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    -webkit-color-adjust: exact;
    color-adjust: exact;
  }

  // @page {
  //   margin: 36pt;
  //   // size: A4;
  //   // page-break-before: always;
  // }
  @page {
    size: auto;
    margin: 0.5in;
  }

  @page :footer {
    color: #fff
  }

  @page :header {
    color: #fff
  }

  img {
    display: block;
  }

  .recipe-thumb {
    width: 100%;
    height: 600pt;
  }

  .page-break {
    padding-top: 32pt;
    margin-top: 16pt;
    // display: block;
    // page-break-before: auto;
    page-break-before: always;
  }

  h2 {
    font-size: 28pt;
    // position: relative;
    // &:before {
    //   content: "";
    //   height: 1pt;
    //   width: 100%;
    //   display: block;
    //   position: absolute;
    //   background-color: #ccc;
    //   top: -.5em
    // }
  }

  p,
  li {
    font-size: 18pt;
    // font-weight: 500;
  }

}

@import "./MealPlanPrint.scss"